import React from "react";
import CTA from "../../components/Cards/CTA";
import Page from "../../components/Page";
import * as styles from "./recension.module.scss"
import data from "../../data/bolag"

export default function Recensioner() {
    const company = "Greenely";
    return (
        <>
            <Page title={"Recension och omdöme av " + company}>
                <div className="container px-5 py-6 mx-auto">
                    <br />
                    <h1 className="font-bold text-4xl md:text-5xl max-w-xl text-gray-900 leading-tight mb-8">Recension och omdöme av {company}</h1>
                    <div className={styles.recension}>
                        <p>Om du letar efter ett elbolag som kan hjälpa dig att spara pengar och bli effektivare med din energianvändning är Greenely definitivt värt att överväga.</p>
                        <p>Deras <strong>smarta teknik och algoritmer</strong> är utformade för att hjälpa dig att optimera din förbrukning, och de erbjuder ett avtal utan åtaganden så att du kan säga upp det när som helst om du inte är nöjd med deras tjänst.</p>
                        <p>I denna artikeln kommer vi gå igenom elbolaget Greenely för att du ska kunna ta reda på om det kan vara ett elbolag för dig.</p>
                        <h2 id="vad-r-greenely-">Vad är Greenely?</h2>
                        <p>Greenely är ett elbolag som använder smart teknik för att hjälpa dig att spara pengar på din elräkning. De har smarta algoritmer som analyserar din elanvändning och ditt beteende och sedan optimerar din förbrukning för att hjälpa dig att bli effektivare med din energianvändning.</p>
                        <p>Bolaget Greenely grundades 2014 av tre tidigare studenter från KTH, vilket gör det till en av de nyare elbolagen.</p>
                        <p>Greenely kan också ses som en uppstickare bland de andra svenska elhandlarna, och de använder ny teknik och algoritmer för att skapa en bättre och smartare tjänst för sina användare.</p>
                        <p>Idag använder över <strong>115 000 hushåll</strong> Greenely och deras app för att kunna optimera sin elförbrukningen och spara pengar.</p>
                        <h2 id="hur-fungerar-greenely-">Hur fungerar Greenely?</h2>
                        <p>Greenely har en smart app som du kan använda för att övervaka och justera din elförbrukning. Appen finns tillgänglig för både iPhone och Android.</p>
                        <p>Appen visar hur mycket el du använder, när du använder den och hur mycket den kostar. Den ger också tips om hur du kan spara pengar på din elräkning.</p>
                        <p>Du kan också justera din elförbrukning via appen. Du kan till exempel ställa in en budget för din elförbrukning och appen justerar automatiskt din förbrukning så att den håller sig inom den budgeten.</p>
                        <p>Du kan också välja att låta appen automatiskt stänga av dina apparater när de inte används eller när elpriserna är höga. Du kan dessutom få varningar när elpriset går upp kraftigt för att hjälpa dig att spara pengar.</p>
                        <h2 id="vad-kostar-elen-hos-greenely-">Vad kostar elen hos Greenely?</h2>
                        <p>Greenely står på frontlinjen vad gäller moderna och billiga elavtal. De erbjuder elen till självkostnadspris åt våra kunder, vilket innebär att ditt elpris är desamma som grossistpriserna på den nordiska elbörsen, utan något pålägg.</p>
                        <p>För deras tjänst betalar du istället en abonnemangskostnad på <strong>69 kr/mån eller 588 kr/år</strong>. Abonnemangsavgiften täcker användningen av Greenelys teknik, kundtjänst och andra driftskostnader.</p>
                        <p>Detta abonnemanget gör också att Greenely får ett incitament att samtidigt göra du att sparar pengar genom att hjälpa dig anpassa din elförbrukning till när den är billigast med smart teknik.</p>
                        <p>För de allra flesta innebär detta en minskning på elkostnaden med 10% varje månad. Men det är fortfarande du som har makten att påverka ditt eget elpris. Greenely ger dig helt enkelt chansen att påverka det själv.</p>
                        <h2 id="vad-inneb-r-smart-elbilsladdning-">Vad innebär smart elbilsladdning?</h2>
                        <p>Om du har en elbil kan Greenely hjälpa dig att spara pengar på laddningskostnaderna. Greenelys algoritmer kan automatiskt att ladda din bil under de billigaste timmarna på dagen, så att du inte behöver oroa dig för det.</p>
                        <p>Detta är ett utmärkt sätt att spara pengar på din elräkning, eftersom laddning av elbilar kan vara en av de största kostnaderna för ägare av elbilar.</p>
                        <p>Greenelys tjänst kan ge dig upp till 50% besparing på dina laddningskostnader, och det går att använda den för bilar från flera olika bilmärken.</p>
                        <h3 id="hur-fungerar-greenelys-smarta-elbilsladdning-">Hur fungerar Greenelys smarta elbilsladdning?</h3>
                        <p>Greenely smarta elbilsladdning är enkel att använda och kräver inga komplicerade installationer.</p>
                        <p>Du behöver bara sätta in laddkabeln till bilen och bestämma när laddningen ska vara slutförd. Greenelys algoritmer identifierar därefter de timmar då elpriset är som billigast och laddar bilen automatiskt till den önskade batterinivån när du vill att elbilen ska vara laddad.</p>
                        <p>Du kan också följa din elbilsladdning i realtid via appen, så att du alltid vet när din bil är laddad och redo att köra</p>
                        <p>Om du har solceller kommer du dessutom se till att du har laddat din elbil med den elen som du själv har producerat, vilket vi själva anser är riktigt kul.</p>
                        <h2 id="greenelys-solcellsavtal">Greenelys solcellsavtal</h2>
                        <p>Greenely erbjuder ett avtal för hushåll med solcellsanläggningar som gör att du kan sälja elen från dina solceller till Greenely.</p>
                        <p>Den solel som du själv inte använder från dina solceller köper Greenely till ett konkurrenskraftigt pris. Du som ny elkund kan sälja till spotpris + 5 öre för varje kWh.</p>
                        <p>Du kan också övervaka hur mycket energi dina solpaneler genererar totalt, hur mycket av din produktion du själv förbrukar och hur mycket av det du producerar du säljer tillbaka till nätet med vinst.</p>
                        <p>Du kan också använda dig av deras Solcellsanalys för att se hur mycket pengar du kan spara för att sälja solcellsel till dme.</p>
                        <h2 id="vad-r-f-rdelarna-med-greenely-">Vad är fördelarna med Greenely?</h2>
                        <p>Greenely är ett modernt elbolag med flera fördelar:</p>
                        <ul>
                            <li>Erbjuder elen till självkostnadspris, vilket innebär att du betalar det pris som står på grossistpriserna för el på den nordiska elbörsen.</li>
                            <li>De som använder Greenely sparar i genomsnitt 10% varje månad på sina elkostnader.</li>
                            <li>Du får en bra överblick på din elanvändningoch produktion i appen.</li>
                            <li>Appen innehåller också en funktion för smart elbilsladdning, vilket gör att du slipper ladda din bil under de dyra timmarna på dygnet.</li>
                            <li>Du kan själv användadin produktion av solel eller sälja den tillbaka till nätet till Greenely.</li>
                        </ul>
                        <p>Som du ser finns det flera fördelar med Greenelys tjänster vilket kan göra att det är värt att byta från ett traditionella elbolag till dem.</p>
                        <h2 id="vad-r-nackdelarna-med-greenely-">Vad är nackdelarna med Greenely?</h2>
                        <p>Det är också viktigt att vara medveten om nackdelarna innan man tar ett abonnemang hos Greenely:</p>
                        <ul>
                            <li>Dyrare vid vissa tillfällen på dygnet eftersom du betalar spotpris elen, och inte kan ha något fast elpris.</li>
                            <li>Det finns en månadsavgift på 69 kr/mån eller 588 kr/år som du behöver betala oavsett hur mycket el som du förbrukar.</li>
                        </ul>
                        <p>Överlag finns det få nackdelar med Greenely, och många hushåll väljer att använda deras tjänster på grund av dessa fördelar.</p>
                        <h2 id="vilket-omd-me-f-r-greenely-fr-n-andra-anv-ndare-">Vilket omdöme får Greenely från andra användare?</h2>
                        <p>Jämfört med många av de andra elbolagen så har Greenely fått ett bra omdöme. De flesta användare upplever att det är enkelt att registrera sig, teckna ett avtal och installera appen.</p>
                        <p>Appen har också fått bra recensioner på grund av deras funktioner. Många tycker om att Greenely till exempel ger dig möjligheten till att se hur mycket energi du producerar från dina solpaneler, och använda den för att ladda din elbil.</p>
                        <p>Det finns dock några mindre bra recensioner av Greenely, och en del klagar bland annat på deras support och att det kan vara krångligt att sätta upp. Om du är mindre teknisk kan det alltså vara en bra idé att ha någon som hjälper dig med installationen.</p>
                        <p>Det är dock viktigt att komma ihåg att Greenely är ett relativt nytt elbolag, och de har därför endast fått ett begränsat antal omdömen hittills.</p>
                        <h2 id="sammanfattning">Sammanfattning</h2>
                        <p>Greenely är ett smart elbolag som använder teknik för att hjälpa dig att spara pengar på din elräkning. Du kan följa din elanvändning i appen och även använda den för att ladda din elbil smart.</p>
                        <p>Greenely är ett också modernt elbolag som erbjuder sina tjänster till ett konkurrenskraftigt pris. De flesta användare verkar vara nöjda med bolaget, även om det finns en del som klagar på deras support och installation.</p>
                        <p>Du kommer även ha störst nytta av Greenelys tjänster om du har elbil eller solceller på grund av deras smarta funktioner för elbilsladdning och solcellsavtal.</p>

                    </div>
                </div>
            </Page>
        </>
    );
}
